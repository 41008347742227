@media only screen and (max-width: 600px) {
  .NavMain {
    width: 100%;
    height: fit-content;
    background-color: rgba(196, 196, 196, 0.953);
    border-radius: 20px 20px 0px 0px;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .Buttons {
    background-color: inherit;
    border: 0;
    font-size: 15px;
    font-weight: 600;
    color: rgb(48, 48, 48);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
    white-space: nowrap;
  }

  .Selected {
    color: green;
  }
}

@media only screen and (min-width: 600px) {
  .NavMain {
    display: none;
  }
}
