.MainLogo {
  width: 300px;
  display: inline-block;
  position: absolute;
  left: 15px;
  top: 5px;
  height: inherit;
  text-align: left;
  z-index: 5;
  user-select: none; /* Standard syntax */
}

.Logo {
  display: inline;
  width: 50px;
  height: 50px;
}

.LogoText {
  display: inline;
  font-size: 20px;
  position: absolute;
  height: inherit;
  width: fit-content;
  text-align: start;
  line-height: 50px; /* The same as your div height */
  font-weight: bold;
}

@media only screen and (max-width: 600px) {
  .Link {
    text-decoration: none; /* no underline */
    color: black;
    pointer-events: none;
  }
}

@media only screen and (min-width: 600px) {
  .Link {
    text-decoration: none; /* no underline */
    color: black;
  }
}
