@media only screen and (max-width: 600px) {
  .Login {
    width: 90%;
    margin: 60px auto;
  }
}

@media only screen and (min-width: 600px) {
  .Login {
    width: 30%;
    margin: 60px auto;
  }
}
