.ListPos {
  width: 40%;
  text-align: center;
  user-select: none; /* Standard syntax */
  min-width: 100px;
}

.Title {
  min-width: 300px;

  width: 100%;
  text-align: center;
  font-weight: bold;
  user-select: none; /* Standard syntax */
  background: linear-gradient(
    to right,
    #15b27e var(--TimeLeft),
    #ffffff var(--TimeLeft)
  );
}
.TimePos {
  min-width: 100px;
  width: 30%;
  text-align: center;
  user-select: none; /* Standard syntax */
}

.selected {
  background-color: #15b27e;
}

.TimeChange {
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
}
.TimeInputText {
  background-color: antiquewhite;
  border-radius: 10px;
  user-select: none; /* Standard syntax */
}
.TimeInput {
  width: 50%;
  border-radius: 10px;
  margin-right: 10px;
  border-color: #004e34;
  text-align: center;
}
.TimeButton {
  border-radius: 10px;
  background-color: #004e34;
  color: azure;
  width: 20%;
}

.TimeFormat {
  min-width: 300px;

  width: 100%;
  text-align: end;
  margin-bottom: 5px;
}
.TimeFormat24 {
  border-radius: 20px 0 0 20px;
  border: 0;
}
.TimeFormat12 {
  border-radius: 0 20px 20px 0;
  border: 0;
}

@media only screen and (max-width: 600px) {
  .TimeFormat {
    display: none;
  }
}
