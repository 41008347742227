@media only screen and (max-width: 600px) {
  .HomeView {
    width: 100%;
  }
  .PostsView {
    position: relative;
    width: 98%;
    margin: 0 1% 0px 1%;
  }
  .PrayerTimes {
    display: none;
  }
  .AddPostBT {
    float: right;
    background-color: hsla(208, 100%, 97%, 0);
    color: #004e34;
    border: 0cap;
    font-weight: bold;
    margin-top: 5px;
  }
}

@media only screen and (min-width: 600px) {
  .HomeView {
    width: 100%;
  }
  .PrayerTimes {
    position: relative;
    width: 30%;
    display: inline-block;
    margin-left: 3%;
    margin-top: 100px;
    vertical-align: top;
  }

  .PostsView {
    margin-top: 20px;
    position: relative;
    width: 45%;
    display: inline-block;
    word-wrap: break-word;
    margin-left: 10%;
  }

  .AddPostBT {
    float: right;
    background-color: hsla(208, 100%, 97%, 0);
    color: #004e34;
    border: 0cap;
    font-weight: bold;
    margin-top: 10px;
  }
}
