.NavPos {
  width: 100%;
  height: 60px;
  background-color: rgba(221, 221, 221, 0.771);
  position: fixed;
  border-radius: 0px 0px 20px 20px;
}

@media only screen and (max-width: 600px) {
  .LinksPos {
    display: none;
  }
}

@media only screen and (min-width: 600px) {
  .LinksPos {
    float: right;
    width: 60%;
    height: inherit;
    line-height: 53px;
    text-align: right;
    text-wrap: nowrap;
  }

  .LinksStyle {
    padding: 23px;
    margin-right: 6%;
    font-weight: 700;
    color: black;
    text-decoration: none; /* no underline */
  }
  .LinksStyle:hover {
    color: var(--secondary);
  }

  .hidden {
    display: none;
  }
}
