.OuterPopup {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(109, 109, 109, 0.652);
  z-index: 6;
}

.InnerPopup {
  position: relative;
  padding: 10px;
  width: fit-content;
  max-width: 500px;
  background-color: rgb(250, 250, 250);
  border-radius: 25px;
  min-width: 280px;
}
.PopupButton {
  margin-left: 2%;
  margin-right: 2%;
  width: 35%;
  min-width: fit-content;
}

.PopupMessage {
  white-space: normal;
  overflow-wrap: break-word;
  width: 75%;
}

.PopupIcon {
  margin-right: 20px;
  color: rgb(255, 230, 0);
  font-size: 40px;
  margin-left: 20px;
}

.TopPart {
  display: flex;
  width: 100%;
  height: 70px;
  white-space: nowrap;
  align-items: center;
}

.Buttons {
  width: 100%;
  margin: auto;
  display: inline-flex;
  justify-content: center;
}
