@media only screen and (max-width: 600px) {
  .About {
    width: 100%;
    align-items: center;
  }
  .Cover {
  }

  .CoverImage {
    width: 100%;
    position: relative;
    height: 350px;
    border-radius: 0px 0px 50px 50px;
  }

  .ImageText {
    position: absolute;
    margin-left: 12%;
    margin-top: 130px;
    font-size: 60px;
    color: rgb(0, 0, 0);
  }
  .Container1 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .h2 {
    text-align: center;
    margin-top: 5%;
    width: 100%;
  }
  .p2 {
    width: 95%;
    margin-top: 5%;
  }
  .AboutMosque {
    display: flex;

    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .h1 {
    margin-top: 3%;
    width: 100%;
    text-align: center;
    margin-bottom: 3%;
  }
  .MosqueImage {
    width: 96%;
    height: 100%;
    border-radius: 20px;
  }
  .AboutMosqueP1 {
    width: 95%;
    margin-top: 3%;
  }
}

@media only screen and (min-width: 600px) {
  .About {
    width: 100%;
    align-items: center;
  }
  .Cover {
  }

  .CoverImage {
    width: 100%;
    position: relative;
    height: 350px;
    border-radius: 0px 0px 50px 50px;
  }

  .ImageText {
    position: absolute;
    margin-left: 12%;
    margin-top: 130px;
    font-size: 60px;
    color: rgb(0, 0, 0);
  }
  .h2 {
    margin-left: 9%;
    margin-top: 1%;
    width: 25%;
    display: inline-block;
    vertical-align: top;
  }
  .p2 {
    display: inline-block;
    width: 50%;
    margin-left: 4%;
    margin-top: 2%;
  }
  .AboutMosque {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .h1 {
    margin-top: 3%;
    width: 100%;
    text-align: center;
    margin-bottom: 3%;
  }
  .MosqueImage {
    width: 70%;
    height: 100%;
    border-radius: 20px;
  }
  .AboutMosqueP1 {
    width: 70%;
    margin-top: 3%;
  }
}
